import React, { useState } from 'react'
import classNames from 'classnames/bind'
import styles from './ProductPrice.module.scss'
import Button from '@material-ui/core/Button'
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

let cx = classNames.bind(styles)

type ProductPriceType = {
   product: any
   location?: Location
}

const parcelas = [11, 10, 9, 8, 7, 6, 5, 4];

const ProductPrice: React.FC<ProductPriceType> = ({ product, location }) => {
   let specialPrice: number | string = 0;
   let valorParcela: number;
   let qtdeParcelas = 12;
   const [priceOpen, setPriceOpen] = useState(false);

   const getParcela = (qtdeParcelas: any) => {
      let valorParcela = 5;
      if (specialPrice >= qtdeParcelas) {
         valorParcela = (+specialPrice) / qtdeParcelas;
      } else {
         if (product.price >= qtdeParcelas) {
            valorParcela = (+product.price) / qtdeParcelas;
         } else {
            do {
               qtdeParcelas = qtdeParcelas - 1;
               valorParcela = (+product.price) / qtdeParcelas;
            } while (valorParcela < 1);
         }
      }
      return valorParcela
   }

   if (product.specialPriceIndividual !== "") {
      specialPrice = product.specialPriceIndividual;
   } else if (product.specialPriceRule !== "") {
      specialPrice = product.specialPriceRule;
   }

   if (specialPrice >= qtdeParcelas) {
      valorParcela = (+specialPrice) / qtdeParcelas;
   } else {
      if (product.price >= qtdeParcelas) {
         valorParcela = (+product.price) / qtdeParcelas;
      } else {
         do {
            qtdeParcelas = qtdeParcelas - 1;
            valorParcela = (+product.price) / qtdeParcelas;
         } while (valorParcela < 1);
      }
   }

   if (product.product_template && (location && !location.pathname.match(/^\/busca/))) {
      const className = cx({
         listPaymentConditions: priceOpen,
         displayNone: !priceOpen
      })
      return (
         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {
               specialPrice !== 0 &&
               <p className={classNames(styles.marginNone, styles.font10, styles.decorationLineThrough)}>DE: R${`${product.price.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</p>

            }
            <p style={{ fontSize: '18px', fontWeight: 'bold' }} className={classNames(styles.marginNone, styles.colorPrimary)}>Por: R${`${(specialPrice !== 0 ? (+specialPrice) : (+product.price)).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</p>
            <div style={{ width: '60%' }}>
               <Button disableFocusRipple onClick={() => setPriceOpen(!priceOpen)} className={styles.buttons} variant='outlined' color='secondary'>
                  <p className={classNames(styles.marginNone, styles.font11)}>{`${qtdeParcelas}x DE R$ ${valorParcela.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} SEM JUROS`}</p>
                  <KeyboardArrowRightRoundedIcon fontSize='small' />
               </Button>
               <List className={className}>
                  {parcelas.map((parcela, index) => {
                     return (
                        <div key={index}>
                           <Divider className={styles.dividerStyle} />
                           <ListItem
                              id="default"
                              className={styles.listItem}
                           >
                              <span className={styles.font11}>{parcela}X DE R${getParcela(parcela).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} SEM JUROS</span>
                           </ListItem>
                        </div>)
                  })}
               </List>
            </div>
         </div>
      )
   } else {
      return (
         <div>
            {
               specialPrice !== 0 &&
               <p className={classNames(styles.marginNone, styles.font10, styles.decorationLineThrough)}>DE: R${`${product.price.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</p>

            }
            <p style={{ fontSize: '14px', fontWeight: 'bold' }} className={classNames(styles.marginNone, styles.colorPrimary)}>Por: R${`${(specialPrice !== 0 ? (+specialPrice) : (+product.price)).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</p>
            <p className={classNames(styles.marginNone, styles.font10)}>{`${qtdeParcelas}x DE R$ ${valorParcela.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} SEM JUROS`}</p>
         </div>
      )
   }
}

export default ProductPrice
